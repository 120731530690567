import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import LegalDoc from '../../modules/legal/components/legal-doc';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = function MerchantPrivacy({
  children
}) {
  return <LegalDoc title="Merchant Privacy Policy" updated="16 Oct 2018">{children}</LegalDoc>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`We take privacy issues very seriously at Enigmatic Smile (“we”, “us”, “our”) and we are fully committed to protecting your privacy. Please read this Privacy Policy carefully as it contains important information regarding how we collect, store, process, transfer, share and use information about you, and information regarding our use of cookies and similar technologies.`}</strong></p>
    <p>{`Please note that any changes to the Privacy Policy will become effective as of the date of posting the revised policy.`}</p>
    <p>{`For the purposes of EU data protection laws (“Data Protection Legislation”), Enigmatic Smile is the data controller (i.e. the company which is responsible for, and controls the processing of, your personal data).`}</p>
    <p>{`This Privacy Policy applies to information we collect and use about visitors to the Merchant Portal. Please read this Privacy Policy carefully in order to understand our views and practices regarding your personal data and how we will treat it.`}</p>
    <ol>
      <li parentName="ol">{`Collection, use and disclosure of personal data`}
        <ol parentName="li">
          <li parentName="ol">{`We collect personal data about you when you voluntarily submit information directly to us when you access and use the Merchant Portal. This can include information you provide to us when you e-mail us using the links on the Merchant Portal, fill in a form, correspond with us via the Merchant Portal, phone, email or otherwise, subscribe to our mailing lists, newsletters or other forms of marketing communications, respond to surveys or use some other feature of the Merchant Portal as available from time to time.`}</li>
          <li parentName="ol">{`If you choose not to provide personal data, we may not be able to provide access to the Merchant Portal to you or respond to your other requests.`}</li>
          <li parentName="ol">{`The table at `}<strong parentName="li">{`Annex 1`}</strong>{` sets out the categories of personal data we collect about you, how we use such information and how it is disclosed. The table also lists the legal basis which we rely on to process the personal data.`}</li>
          <li parentName="ol">{`We also automatically collect personal data about you indirectly, including about how you access and use the Merchant Portal and information about the device you use to access the Merchant Portal.`}</li>
          <li parentName="ol">{`The table at `}<strong parentName="li">{`Annex 2`}</strong>{` sets out the categories of personal data we collect about you automatically and how we use that information. The table also lists the legal basis which we rely on to process the personal data.`}</li>
          <li parentName="ol">{`We may link or combine the personal data we collect about you and the information we collect automatically. This allows us to provide you with a personalised experience regardless of how you interact with us.`}</li>
          <li parentName="ol">{`We may anonymise and aggregate any of the personal data we collect (so that it does not directly identify you). We may use anonymised information for our business purposes including testing our IT systems, research, data analysis, improving the Merchant Portal and developing new products and features. We may also share such anonymised information with others.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Disclosures of your personal data`}
        <ol parentName="li">
          <li parentName="ol">{`We may have to share your personal data with the following parties for the purposes set out as follows, as further detailed in `}<strong parentName="li">{`Annex 1`}</strong>{` and `}<strong parentName="li">{`Annex 2`}</strong>{`:`}
            <ol parentName="li">
              <li parentName="ol"><strong parentName="li">{`Service providers and advisors.`}</strong>{` Personal data may be disclosed to third party vendors and other service providers that perform services for us, on our behalf, which may include identifying and serving targeted advertisements (for example, on Facebook and Twitter), providing payment, mailing or email services, tax and accounting services, data enhancement services, fraud prevention services, web hosting, and/or analytic services.`}</li>
              <li parentName="ol"><strong parentName="li">{`Purchasers and third parties in connection with a business transaction.`}</strong>{` Personal data may be disclosed to third parties in connection with a transaction, such as a merger, sale of assets or shares, reorganisation, financing, change of control or acquisition of all or a portion of our business.`}</li>
              <li parentName="ol"><strong parentName="li">{`Law enforcement, regulators and other parties for legal reasons.`}</strong>{` Personal data may be disclosed to third parties as required by law or if we reasonably believe that such action is necessary to (a) comply with the law and the reasonable requests of law enforcement; (b) to protect the security or integrity of the service; and/or (c) exercise or protect the rights, property, or personal safety of users of the Service or others.`}</li>
            </ol>
          </li>
        </ol>
      </li>
      <li parentName="ol">{`Retention periods`}
        <ol parentName="li">
          <li parentName="ol">{`We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for (as set out in paragraph 2.1 above, and in `}<strong parentName="li">{`Annex 1`}</strong>{` and `}<strong parentName="li">{`Annex 2`}</strong>{` below), including for the purpose of satisfying and legal, accounting, or reporting requirements.`}</li>
          <li parentName="ol">{`To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data, and whether we can achieve those purposes through other means, and the applicable legal requirements.`}</li>
          <li parentName="ol">{`In some circumstances, you can ask us to delete your data: see paragraph 5.2.4 below for further information.`}</li>
          <li parentName="ol">{`In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Storing and transferring your personal data`}
        <ol parentName="li">
          <li parentName="ol"><strong parentName="li">{`Security.`}</strong>{` We implement appropriate technical and organisational measures to protect your personal data against accidental or unlawful destruction, loss, change or damage. All personal data we collect will be stored on secure servers. All electronic transaction entered into via the Merchant Portal will be protected by SSL encryption technology.`}</li>
          <li parentName="ol"><strong parentName="li">{`International Transfers of your Personal Data.`}</strong>{` Your personal data may be transferred to, and processed and stored in, countries outside of the jurisdiction you are in where we and our third party service providers have operations. If you are located in the European Economic Area (`}<strong parentName="li">{`“EEA”`}</strong>{`), your personal data may be processed outside of the EEA, including, for example, in the United States (an `}<strong parentName="li">{`“International Transfer”`}</strong>{`). Any International Transfers of your personal data are made either:`}
            <p>(a) to a country or territory ensuring an adequate level of protection for the rights and freedoms of data subjects in relation to the processing of personal data as determined by the European Commission;</p>
            <p>(b) to a third party that is a member of a compliance scheme recognised as offering adequate protection for the rights and freedoms of data subjects as determined by the European Commission, such as the EU-U.S. Privacy Shield; or</p> 
            <p>(c) pursuant to appropriate safeguards, such as the Standard Contractual Clauses (processors) approved by European Commission Decision C(2010)593 or any subsequent version thereof released by the European Commission. If you wish to enquire further about these safeguards used, please contact us using the details set out at the end of this Policy.</p>
          </li>
        </ol>
      </li>
      <li parentName="ol">{`Your rights in respect of your personal information`}
        <ol parentName="li">
          <li parentName="ol">{`This section applies to you if you are located in the EEA.`}</li>
          <li parentName="ol">{`In accordance with applicable privacy law, you have the following rights in respect of your personal information that we hold:`}
            <ol parentName="li">
              <li parentName="ol"><strong parentName="li">{`Right of access.`}</strong>{` You have the right to obtain:`}
                <ol parentName="li">
                  <li parentName="ol">{`confirmation of whether, and where, we are processing your personal information;`}</li>
                  <li parentName="ol">{`information about the categories of personal information that we are processing, the purposes for which we process your personal information, and information as to how we determine applicable retention periods;`}</li>
                  <li parentName="ol">{`information about the categories of recipients with whom we may share your personal information; and`}</li>
                  <li parentName="ol">{`a copy of the personal information we hold about you.`}</li>
                </ol>
              </li>
              <li parentName="ol"><strong parentName="li">{`Right of portability.`}</strong>{` You have the right, in certain circumstances, to receive a copy of the personal information you have provided to us in a structured, commonly used, machine-readable format that supports re-use, or to request the transfer of your personal data to another person.`}</li>
              <li parentName="ol"><strong parentName="li">{`Right to rectification.`}</strong>{` You have the right to obtain rectification of any inaccurate or incomplete personal information that we hold about you without undue delay.`}</li>
              <li parentName="ol"><strong parentName="li">{`Right to erasure.`}</strong>{` You have the right, in some circumstances, to require use to erase your personal information without undue delay, if the continued processing of that personal information is not justified.`}</li>
              <li parentName="ol"><strong parentName="li">{`Right to restriction.`}</strong>{` You have the right, in some circumstances, to require us to limit the purposes for which we process your personal information if the continued processing of the personal information in this way is not justified, such as where the accuracy of the personal information is contested by you, for a period enabling us to verify the accuracy of that personal information.`}</li>
              <li parentName="ol"><strong parentName="li">{`Right of objection.`}</strong>{` You have a right to object to processing of your personal information, based on legitimate interests and direct marketing.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`If you wish to exercise one of these rights, please contact us using the contact details at the end of this Privacy Policy.`}</li>
          <li parentName="ol">{`You also have the right to lodge a complaint to your local data protection authority. Further information about how to contact your local data protection authority is available at `}<a parentName="li" {...{
              "href": "https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
            }}>{`https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm`}</a>{`.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Cookies and similar technologies`}
        <ol parentName="li">
          <li parentName="ol">{`The Merchant Portal uses cookies and similar technologies to distinguish you from other users of the Merchant Portal. This helps us to provide you with a good experience when you browse the Merchant Portal and also allows us to improve the Merchant Portal.`}</li>
          <li parentName="ol">{`Cookies are pieces of code that allow for personalisation of the Merchant Portal experience by saving your information such as user ID and other preferences. A cookie is a small data file that we transfer to your device’s hard disk (such as your computer or smartphone) for record-keeping purposes.`}</li>
          <li parentName="ol">{`We use the following types of cookies:`}
            <ol parentName="li">
              <li parentName="ol"><strong parentName="li">{`Strictly necessary cookies.`}</strong>{` These are cookies that are required for the essential operation of the Merchant Portal such as to authenticate users and prevent fraudulent use.`}</li>
              <li parentName="ol"><strong parentName="li">{`Analytical/performance cookies.`}</strong>{` These allow us to recognise and count the number of visitors and to see how visitors move around the Merchant Portal when they are using it. This helps us to improve the way the Merchant Portal works, for example, by ensuring that users are finding what they are looking for easily.`}</li>
              <li parentName="ol"><strong parentName="li">{`Functionality cookies.`}</strong>{` These are used to recognise you when you return to the Merchant Portal. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).`}</li>
              <li parentName="ol"><strong parentName="li">{`Targeting cookies.`}</strong>{` These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it, and the marketing messages we send to you more relevant to your interests. We may also share this information with third parties who provide a service to us for this purpose.`}</li>
              <li parentName="ol"><strong parentName="li">{`Third party cookies.`}</strong>{` Please be aware that advertisers and other third parties may use their own cookies tags when you click on an advertisement or link on our website. These third parties are responsible for setting out their own cookie and privacy policies.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`The cookies we use are designed to help you get the most from the Merchant Portal but if you do not wish to receive cookies, most browsers allow you to change your cookie settings. Please note that if you choose to refuse cookies you may not be able to use the full functionality of the Merchant Portal. These settings will typically be found in the “options” or “preferences” menu of your browser. In order to understand these settings, the following links may be helpful, otherwise you should use the “Help” option in your browser for more details.`}
            <ol parentName="li">
              <li parentName="ol"><a parentName="li" {...{
                  "href": "https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
                }}>{`Cookie settings in Internet Explorer`}</a></li>
              <li parentName="ol"><a parentName="li" {...{
                  "href": "https://support.mozilla.org/en-US/kb/cookies"
                }}>{`Cookie settings in Firefox`}</a></li>
              <li parentName="ol"><a parentName="li" {...{
                  "href": "https://support.google.com/chrome/answer/95647?hl=en"
                }}>{`Cookie settings in Chrome`}</a></li>
              <li parentName="ol"><a parentName="li" {...{
                  "href": "https://help.apple.com/safari/mac/9.0/#/sfri11471"
                }}>{`Cookie settings in Safari web`}</a>{` and `}<a parentName="li" {...{
                  "href": "https://support.apple.com/en-gb/HT201265"
                }}>{`iOS`}</a></li>
            </ol>
          </li>
          <li parentName="ol">{`We participate in interest-based advertising and use third party advertising companies to serve you targeted advertisements based on your online browsing history and your interests. To do this, we or our advertising partners may collect information about how you use or connect to the Merchant Portal, or the types of other websites, social media services, content and ads that you (or others using your device) visit or view or connect to the Merchant Portal. Typically, this information is collected through cookies and similar tracking technologies.`}</li>
          <li parentName="ol">{`If you only want to limit third party advertising cookies, you can turn such cookies off by visiting the following links (please bear in mind that there are many more companies listed on these sites than those that drop cookies via our website):`}
            <ol parentName="li">
              <li parentName="ol"><a parentName="li" {...{
                  "href": "http://www.youronlinechoices.com/"
                }}>{`Your Online Choices`}</a></li>
              <li parentName="ol"><a parentName="li" {...{
                  "href": "https://www.networkadvertising.org/"
                }}>{`Network Advertising Initiative`}</a></li>
              <li parentName="ol"><a parentName="li" {...{
                  "href": "https://www.aboutads.info/consumers"
                }}>{`Digital Advertising Alliance`}</a></li>
            </ol>
          </li>
          <li parentName="ol">{`We also use clear gifs in HTML-based emails sent to our Customers to track which emails are opened and which links are clicked by recipients. The information allows for more accurate reporting and improvement of the Merchant Portal. You can set your e-mail options to prevent the automatic downloading of images that may contain these technologies that would allow us to know whether you have accessed our e-mail and performed certain functions with it.`}</li>
          <li parentName="ol">{`If you would like to find out more about cookies and other similar technologies, please visit `}<a parentName="li" {...{
              "href": "https://www.networkadvertising.org/"
            }}>{`allaboutcookies.org`}</a>{` or the Network Advertising Initiative’s online resources at `}<a parentName="li" {...{
              "href": "https://www.networkadvertising.org/"
            }}>{`networkadvertising.org`}</a>{`.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Links to third party sites`}
        <p>The Merchant Portal may, from time to time, contain links to and from third party services. If you follow a link to any of these services, please note that these services have their own privacy policies and that we do not accept any responsibility or liability for their policies. Please check the individual policies before you submit any information to those services.</p>
      </li>
      <li parentName="ol">{`Our policy towards children`}
        <p>The Merchant Portal is not directed at persons under 18 and we do not knowingly collect personal data from persons under 18.</p>
      </li>
      <li parentName="ol">{`Changes to this policy`}
        <p>We evaluate our privacy policies and procedures to implement improvements and refinements from time to time. Accordingly, we may update this Privacy Policy from time to time, and so you should review this page periodically. If we make material changes to this Privacy Policy, we will update the “last updated” data at the start of this Privacy Policy. Changes to this Privacy Policy are effective when they are posted on this page.</p>
      </li>
      <li parentName="ol">{`Notice to you`}
        <p>If we need to provide you with information about something, whether for legal, marketing or other business related purposes, we will select what we believe is the best way to get in contact with you. We will usually do this through email or by placing a notice on the Merchant Portal. The fact that we may send notices to you will not stop you from being able to opt out of certain types of contact as described in this Privacy Policy.</p>
      </li>
      <li parentName="ol">{`Contacting us`}
        <p>Questions, comments and requests regarding this Policy are welcome and should be addressed to <strong><a href="mailto:hello@fidelapi.com">hello@fidelapi.com</a></strong>.</p>
      </li>
    </ol>
    <h2>{`Annex 1`}</h2>
    <h3>{`Personal information we may collect when you use the merchant portal`}</h3>
    <div className="legal-table-wrapper">
  <table className="legal-table three-col-legal-table">
    <thead>
      <tr>
        <th>Category of personal information</th>
        <th>How we use it</th>
        <th>Legal basis for the processing</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Contact information and basic personal details such as your first name, last name, phone number, and e-mail address.</td>
        <td>
        We use this information to operate, maintain and provide to you the features of the Merchant Portal.
          <br />
        We use this information to communicate with you, including sending Merchant Portal-related communications (such as invoices and information about updates to the Merchant Portal, and any news, alerts and marketing communications (in line with your settings and options)).
          <br />
        We use this information to deal with enquiries and complaints made by or about you relating to the Merchant Portal.
        </td>
        <td>
        The processing is necessary for our legitimate interests, namely administering the Merchant Portal, for marketing purposes and communicating with users.
        </td>
      </tr>
      <tr>
        <td>Information provided by third parties. From time to time, we may receive information about you from third parties and other users. We may obtain information from third parties to enhance or supplement our existing user information. We may also collect information about you that is publicly available.</td>
        <td>
        We may combine this information with the information we collect from you directly. We use this information to contact you, to send you advertising or promotional materials or to personalise our Merchant Portal, to pre-populate online forms, and to better understand the demographics of our users.
        </td>
        <td>
       The processing is necessary for our legitimate interests, namely to tailor our Merchant Portal to the user and to improve our Merchant Portal generally.
        </td>
      </tr>
      <tr>
        <td>All personal information set out above.</td>
        <td>
        We will use all the personal information we collect to operate, maintain and provide to you the features and functionality of the Merchant Portal, to communicate with you, to monitor and improve our Merchant Portal and business, and to help us develop new products and services.
        </td>
        <td>
       The processing is necessary for our legitimate interests, namely to administer and improve the Merchant Portal.
        </td>
      </tr>
    </tbody>
  </table>
    </div>
    <h2>{`Annex 2`}</h2>
    <h3>{`Personal information collected automatically`}</h3>
    <div className="legal-table-wrapper">
  <table className="legal-table three-col-legal-table">
    <thead>
      <tr>
        <th>Category of personal information</th>
        <th>How we use it</th>
        <th>Legal basis for the processing</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Information about how you access and use the Merchant Portal including, for example, how frequently you access the Merchant Portal, the time you access the Merchant Portal and how long you use the Merchant Portal for, whether you access the Merchant Portal from multiple devices, the website from which you came and the website to which you go when you leave the Merchant Portal, and other actions you take on the Merchant Portal.</td>
        <td>
        We use this information to present the Merchant Portal to you on your device.
          <br />
        We use this information to administer the Merchant Portal for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes, and to help us develop new products and services.
          <br />
        We use this information to detect and prevent fraud.
        </td>
        <td>
        The processing is necessary for our legitimate interests, namely to tailor the Merchant Portal to the user and improve the Merchant Portal generally.
          <br />
        The processing is necessary for our legitimate interests, namely communicating with users and responding to queries, complaints, and concerns, and for developing and improving the Merchant Portal.
          <br />
        The processing is necessary for our legitimate interests, namely the detection and prevention of fraud.
        </td>
      </tr>
      <tr>
        <td>All personal information set out above.</td>
        <td>
        We will use all the personal information we collect to operate, maintain and provide to you the features and functionality of the Merchant Portal, to monitor and improve our Merchant Portal and business, and to help us develop new products and services.
        </td>
        <td>
       The processing is necessary for our legitimate interests, namely to administer and improve the Merchant Portal.
        </td>
      </tr>
    </tbody>
  </table>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      